	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Slider functionality
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/

import  './vendor/flickity.min';

(function($) {


    $('.icon-slider').each(function () {
        let _this = $(this);
        var args = {
          pageDots: false,
          wrapAround: true,
          cellSelector: '.wp-block-image',
          percentPosition: true,
          contain: true,
          setGallerySize: true,
          resize: true,
          imagesLoaded: true,
          cellAlign: 'center',
          freeScroll: false,
          prevNextButtons: false,
          autoPlay: 4000,
          selectedAttraction: 0.008,
          friction: 0.16
        };
  
        var $carousel = _this.flickity(args);
        $carousel.flickity(args);
    });


    $('.testimony-block-slider').each(function () {
        let _this = $(this);
        var args = {
          pageDots: false,
          wrapAround: true,
          cellSelector: '.testimony',
          percentPosition: true,
          contain: true,
          setGallerySize: true,
          resize: true,
          imagesLoaded: true,
          cellAlign: 'center',
          freeScroll: false,
          prevNextButtons: true,
          autoPlay: 8000,
          selectedAttraction: 0.008,
          friction: 0.16
        };
  
        var $carousel = _this.flickity(args);
        $carousel.flickity(args);
    });


	$('.slider').each(function () {
	  let _this = $(this);
	  var args = {
	    pageDots: false,
	    wrapAround: true,
	    cellSelector: '.slide',
	    percentPosition: true,
	    contain: true,
	    setGallerySize: false,
	    resize: true,
	    imagesLoaded: true,
	    cellAlign: 'left',
	    freeScroll: false,
	    prevNextButtons: false,
	    // autoPlay: 4000,
	    // selectedAttraction: 0.008,
	    // friction: 0.16
	  };

	  var $carousel = _this.flickity(args);
	  //Destroy
	  // $carousel.flickity('destroy');
	  //Re-init
	  $carousel.flickity(args);
	  // $carousel.flickity('reloadCells')

	  // $(this).on('click', function () {
	  // 	$carousel.flickity('next')
	  // });

	  // $carousel.on('dragStart.flickity', () => $carousel.find('.slide').css('pointer-events', 'none'));
	  // $carousel.on('dragEnd.flickity', () => $carousel.find('.slide').css('pointer-events', 'all'));
	});

	$('.info-slider').each(function () {
	  let _this = $(this);
	  var args = {
	    pageDots: false,
	    wrapAround: true,
	    cellSelector: '.slide',
	    percentPosition: true,
	    contain: true,
	    setGallerySize: false,
	    resize: true,
	    imagesLoaded: true,
	    cellAlign: 'left',
	    freeScroll: false,
	    prevNextButtons: false,
	    autoPlay: 4000,
	    selectedAttraction: 0.008,
	    friction: 0.16
	  };

	  var $carousel = _this.flickity(args);
	  //Destroy
	  // $carousel.flickity('destroy');
	  //Re-init
	  $carousel.flickity(args);
	  // $carousel.flickity('reloadCells');
	});

	$('.home-gallery').each(function () {
        let _this = $(this);
        var args = {
            pageDots: false,
            wrapAround: true,
            cellSelector: '.wp-block-image',
            percentPosition: true,
            contain: true,
            setGallerySize: true,
            resize: true,
            imagesLoaded: true,
            cellAlign: 'center',
            freeScroll: false,
            prevNextButtons: false,
            autoPlay: 4000,
            selectedAttraction: 0.008,
            friction: 0.16
        };

        var $carousel = _this.flickity(args);
        $carousel.flickity(args);
	});

    $('.service-gallery').each(function () {
        let _this = $(this);
        var args = {
            pageDots: false,
            wrapAround: true,
            cellSelector: '.wp-block-image',
            percentPosition: true,
            contain: true,
            setGallerySize: true,
            resize: true,
            imagesLoaded: true,
            cellAlign: 'center',
            freeScroll: false,
            prevNextButtons: false,
            autoPlay: 4000,
            selectedAttraction: 0.008,
            friction: 0.16
        };

        var $carousel = _this.flickity(args);
        $carousel.flickity(args);
	});

})( jQuery );